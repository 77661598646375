import React from 'react';

function Freezer() {
  return (
    <div>
      <h2>Dashboard</h2>
      <p>This is the Dashboard page content.</p>
    </div>
  );
}

export default Freezer;

import React, { useEffect, useState } from 'react';
import { Container, Navbar, Nav, Button } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

// Import your page components
import Freezer from './pages/Freezer';
import Reports from './pages/Reports';
import Analytics from './pages/Analytics';
import Home from './pages/Home';

function App() {
  const [message, setMessage] = useState('');
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    fetch('/api/hello/')
      .then(response => response.json())
      .then(data => setMessage(data.message))
      .catch(error => console.error('Error fetching message:', error));
  }, []);

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  return (
    <Router>
      <div className="app-container">
        <Navbar bg="dark" variant="dark" expand="lg">
          <Container fluid className="p-0">
            <div className="brand-container bg-dark d-flex align-items-center">
              <Navbar.Brand as={Link} to="/">Find Shop Save</Navbar.Brand>
              <Button 
                onClick={toggleSidebar} 
                className="d-lg-none sidebar-toggle ms-auto"
                aria-label="Toggle Sidebar"
              >
                ☰
              </Button>
            </div>
            <div className="nav-content">
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link as={Link} to="/">Home</Nav.Link>
                  <Nav.Link as={Link} to="/projects">Projects</Nav.Link>
                  <Nav.Link as={Link} to="/about">About</Nav.Link>
                </Nav>
                <Button variant="outline-danger" className="d-none d-lg-block">Sign Out</Button>
              </Navbar.Collapse>
            </div>
          </Container>
        </Navbar>

        <div className="content-wrapper">
          <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
            <Nav className="flex-column mt-3">
              <Nav.Link as={Link} to="/freezer">Freezer Manager</Nav.Link>
              <Nav.Link as={Link} to="/reports">Reports</Nav.Link>
              <Nav.Link as={Link} to="/analytics">Analytics</Nav.Link>
              <Nav.Link href="#signout" className="d-lg-none text-danger">
                Sign Out
              </Nav.Link>
            </Nav>
          </div>
          <div className="main-content">
            <Routes>
              <Route path="/" element={<Home message={message} />} />
              <Route path="/freezer" element={<Freezer />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/analytics" element={<Analytics />} />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
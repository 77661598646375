import React from 'react';

function Home({ message }) {
  return (
    <div>
      <h1>Welcome to Find Shop Save</h1>
      <p>This is the main content area.</p>
      <p>{message}</p>
    </div>
  );
}

export default Home;

import React from 'react';

function Reports() {
  return (
    <div>
      <h2>Reports</h2>
      <p>This is the Reports page content.</p>
    </div>
  );
}

export default Reports;
